<section class='about-container row align-items-md-start'>
  <div class='about-info-container col-md-5 col-sm-12 '>
    <h2>El buscador</h2>
    <p>
      El buscador de la Plataforma UNE OA es el punto de acceso principal de consulta y acceso la producción científica
      en acceso abierto de los diferentes Servicios de Publicación que forman parte de la UNE que en ella se albergan
      y preservan.
    </p>
    <p>
      Te permitirá conocer qué libros han publicado sobre un tema cualquier Servicio de Publicación que forme parte de
      la UNE.
    </p>
    <p>En esta sección se explica las diferentes opciones de búsqueda que dispone la plataforma,
      cómo proceder en la ejecución de cada tipo de búsqueda, la presentación de la página de resultados y
      opciones de filtrado o refinamiento de la búsqueda, y el acceso a la información detallada del registro
      en el que se esté interesado para decidir si proceder a su consulta/compartición/descarga.
    </p>

    <p><strong>Búsqueda en la Plataforma UNE OA en “X” pasos:</strong></p>
    <p>
      Selecciona el modo de búsqueda: simple, avanzada o por índice de búsqueda prestablecida, a continuación, Filtra u
      Ordena tus resultados, y finalmente, el acceso al recurso deseado y la posibilidad de
      gestionar la exportación de su cita o su compartición a través de medios sociales.
    </p>

    <p><strong>Explicación de la ejecución de una búsqueda avanzada:</strong></p>
    <p>
      En la opción de búsqueda avanzada se presenta la posibilidad de buscar diferentes términos mediante
      la combinación de estos a través de diferentes cajones de búsqueda.
    </p>
    <p>
      Agregación de cajones para la combinación de los diferentes términos/campos de búsqueda. Se pueden añadir tantos
      cajones
      como términos en los que se esté interesado en especificar o concretar en la búsqueda realizar.
    </p>
    <p>
      En cada cajón de término a especificar se deberá seleccionar del desplegable el tipo de campo por el que buscar.
      Listado de posibilidades:
    </p>

    <ul>
      <li> Servicio de Publicación.</li>
      <li>Título.</li>
      <li>Autor/Creador (Nombre o Apellido/s) u ORCID</li>
      <li>Lengua/Idioma de la publicación.</li>
      <li>Área temática / Materia [se facilita listado desplegable de posibilidades]</li>
      <li>Colección (Categoría Temática) [se facilita listado desplegable de posibilidades] Para aplicar una vez se haya
        seleccionado un área temática concreta en la que buscar [+(Categoría Temática)] </li>

    </ul>
    <p>
      Junto a los cajones de búsqueda se podrá especificar la concreción de la búsqueda también por:
      <li>Año de publicación: Desde [AAAA-MM-DD] – Hasta.</li>
      <li>Botón (activar/desactivar) de Publicaciones en Acceso Abierto (se excluyen las publicaciones con Embargo)</li>
      <li>
        Botones de Tipo de recurso:
      </li>

      <li> Libro [+ Subtipologías de Libro] - Capítulos de Libro.</li>

      <li> Botones de “Con Sello CEA-APQ”: - De Colección -De Monografías.</li>

    </p>

    <p><strong>Explicación de la ejecución de una búsqueda a través de los índices de búsqueda prestablecida:
      </strong></p>

    <p>
      Entre las opciones que presenta la Plataforma UNE OA de navegación por Índices/Listado predefinido de búsquedas,
      se encuentran las siguientes:
    </p>
    <ul>
      <li> Libros por Materia / Área temática.</li>
      <li>Libros por Editorial.</li>
      <li>Libros por Colección (de cada Servicios de Publicación).[Colecciones por Materia/s]</li>
      <li>Libros por Autor (listado alfabético por Apellido de Autor).</li>

    </ul>
    <p>
      Los índices son listados de búsquedas prestablecidas [ordenados alfabéticamente] facilitan la navegación entre los
      resultados de manera intuitiva. En la parte superior se facilita el abecedario para que, al pinchar en la letra en
      cuestión, nos dirija a los resultados que comiencen con esta letra.
    </p>
    <p><strong>
        Explicación de la ejecución de Filtros y Ordenación de resultados en la página de presentación de resultados de
        nuestra búsqueda:
      </strong></p>

    <p>
      Una vez se nos presentan los resultados de nuestra búsqueda ejecutada, podemos aún refinarlos u ordenarlos para
      localizar concretamente la publicación o publicaciones en la/s que estemos interesado.
    </p>
    <p>
      Entre las <strong>Facetas</strong> de refinamiento o filtro que posibilita la plataforma junto a los resultados se
      encuentran las siguientes:
    </p>
    <ul>
      <li> Autor/Creador [Apellidos, Nombre].</li>
      <li>Año de Publicación.</li>
      <li>Servicio de Publicaciones (normalizados)</li>
      <li>Área Temática / Materia (áreas de ANECA).</li>
      <li> Palabras clave</li>
      <li>Tipo de recurso/documento (Libro vs. Capítulo).</li>
      <li>[+] En Libro: Subtipología de Libro.</li>
      <li>Lengua de la publicación.</li>
      <li>Tipo de Formato de publicación.</li>
      <li>Colección (Servicios de Publicación) [vinculadas por Materias]</li>
      <li>Sello CEA-APQ (Colección / Monografía)</li>

    </ul>
<p>    Posibilidades de ordenación de los resultados por página:</p>
<ul>
   Opciones de ordenación:
<li>Relevancia</li>
 <li>Título Asc/Des</li>
<li> Fecha de Difusión Asc/Des</li>
 Resultados mostrados por página [5-10-20-40-60-80-100]
</ul>

<p><strong>Gestión de ítems</strong></p>
<p>Una vez se ha llegado a la pantalla de descripción del ítem deseado, la plataforma permite las siguientes posibilidades:</p>
<ul>
<li>  Acceso y descarga del documento en diferentes formatos.</li>
<li> Acceso a la página web del recurso en la página del editor.</li>
<li> Acceso a la versión de compra del recurso en Unebook.</li>
<li>Visualización de los campos de descripción del recurso en modo usuario.</li>
<li>Visualización de los campos de metadatos que configuran la descripción del ítem.</li>
<li>Descubribilidad de otros recursos en función de los campos vinculados que componen la descripción [p.ej.: Las palabras clave permiten, pinchando en cada una de ellas, llevarte al conjunto de ítems que comparten esa misma palabra clave; Colecciones: pinchando en cada una, navegación por el conjunto de ítems pertenecientes a la misma colección. …].</li>
<li> Gestionar la exportación de la cita del recurso a diferentes gestores bibliográficos.</li>
<li>Compartición de la cita del ítem a través de correo-e u otros medios sociales (ver posibilidades: ej. Plugin X(Twitter), LinkedIn, otros…).</li>
</ul>


  </div>
  <div class="col-md-2 col-sm-12 mt-3 fixed-sidebar">
    <div class="about-card">
      <li><a routerLink="/quienes-somos">Quienes Somos</a></li>
      <li><a routerLink="/buscador">El buscador</a></li>
      <li><a routerLink="/tecnologia">Tecnología</a></li>
      <li><a routerLink="/politica-servicios">Política y servicios</a></li>
    </div>
  </div>
</section>