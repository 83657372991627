import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'ds-fags',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './fags.component.html',
  styleUrl: './fags.component.scss'
})
export class FagsComponent {

}
