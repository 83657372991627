import { Component } from '@angular/core';

@Component({
  selector: 'ds-statistics-card',
  standalone: true,
  imports: [],
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.style.scss'],
})
export class StatisticsCardComponent { }

