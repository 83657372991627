<section class="faq-section">
    <h2 class="faq-title">Preguntas frecuentes</h2>
    <ol class="faq-list">
      <li>¿Qué es y para qué sirve la Plataforma UNE OA?</li>
      <li>¿Qué ventajas tiene depositar en un repositorio como la Plataforma UNE OA?</li>
      <li>¿Quién puede depositar en la Plataforma UNE OA?</li>
      <li>¿Qué tipo de documentos se pueden depositar en la Plataforma UNE OA?</li>
      <li>¿Cómo están organizados los contenidos?</li>
      <li>¿Puedo solicitar la creación de una nueva colección?</li>
      <li>¿Qué versión del documento se debe/puede depositar?</li>
      <li>¿Qué formatos de archivos se pueden archivar?</li>
      <li>¿Existen limitaciones de tamaño para los archivos que pueden depositar en la Plataforma UNE OA?</li>
      <li>¿Cómo se envían o remiten los archivos para su depósito en la Plataforma UNE OA?</li>
      <li>¿Qué nombre debo dar a los archivos?</li>
      <li>¿Puedo eliminar o modificar un archivo una vez depositado en la Plataforma UNE OA?</li>
      <li>¿Tengo que registrarme para poder acceder?</li>
      <li>¿Qué es y para qué sirve la licencia Creative Commons y cuándo es oportuno asignarla?</li>
      <li>¿Por qué debo conceder a la Plataforma UNE OA la licencia no exclusiva de distribución?</li>
    </ol>
  </section>
  
