<ds-metadata-field-wrapper [label]="label">
  <ng-container *ngFor="let objectPage of objects; let i = index">
    <ng-container *ngVar="(objectPage | async) as representations">
      <div style="display: flex; flex-direction: column;">
        <div *ngFor="let rep of representations" style="display: flex; align-items: center; margin-bottom: 8px;">
          <!-- Representación del autor con botón 'ID' inmediatamente después -->
          <ds-metadata-representation-loader [mdRepresentation]="rep" style="margin-right: 8px;"></ds-metadata-representation-loader>
          <button class="id-btn" style="display: inline-block; margin-left: 4px;">
            id
          </button>
        </div>
      </div>
      <ds-loading *ngIf="(i + 1) === objects.length && (i > 0) && (!representations || representations?.length === 0)" 
                  message="{{'loading.default' | translate}}">
      </ds-loading>
      <div class="d-inline-block w-100 mt-2" *ngIf="(i + 1) === objects.length && representations?.length > 0">
        <div *ngIf="(objects.length * incrementBy) < total" class="float-left">
          <button class="btn btn-link btn-link-inline" (click)="increase()">
            {{'item.page.related-items.view-more' | translate:{ amount: (total - (objects.length * incrementBy) < incrementBy) ? total - (objects.length * incrementBy) : incrementBy } }}
          </button>
        </div>
        <div *ngIf="objects.length > 1" class="float-right">
          <button class="btn btn-link btn-link-inline btn-primary" (click)="decrease()">
            {{'item.page.related-items.view-less' | translate:{ amount: representations?.length } }}
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ds-metadata-field-wrapper>
