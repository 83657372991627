<div class="background-bg-container mt-ncs">
  <div class="container">
    <div class="jumbotron jumbotron-fluid">
      <div class="d-flex flex-wrap justify-content-center align-items-center">
        <div>
          <h2 class="font-weight-bold h2">Plataforma de publicaciones en acceso abierto de la UNE</h2>
          <h6 class="sub-title h6 fw-light sub-title">Encuentra libros de las universidades españolas y centros de investigación</h6>
          <div class="mt-3"><ds-search-navbar></ds-search-navbar></div>
        </div>
      </div>
    </div>
  </div>
  <picture class="background-image">
    <source type="image/webp" srcset="assets/dspace/images/banner.webp 2000w, assets/dspace/images/banner-half.webp 1200w, assets/dspace/images/banner-tall.webp 768w">
    <source type="image/jpg" srcset="assets/dspace/images/banner.jpg 2000w, assets/dspace/images/banner-half.jpg 1200w, assets/dspace/images/banner-tall.jpg 768w">
    <img alt="" [src]="'assets/dspace/images/banner.jpg'"/>
  </picture>
</div>
<!--Componente que muestra estadisticas-->

<ds-statistics-card />