
<div class="container">
    <div class="contact-info">
      <h2>Contacto</h2>

      <!--Información de contacto-->
      <div class="contact-list">
        <div class="contact-section">
          <h2>SECRETARÍA TÉCNICA</h2>
          <div class="contact-item">
            <p>Joaquín Corbacho</p>
            <p>Teléfono: 913 600 698</p>
            <a href="mailto:secretariatecnica@une.es">secretariatecnica&#64;une.es</a>
          </div>
          <div class="contact-item">
            <p>Marta García Palomo</p>
            <p>Teléfono: 913 600 698</p>
            <a href="mailto:secretariatecnica3@une.es">secretariatecnica3&#64;une.es</a>
          </div>
        </div>
        
        <div class="contact-section">
          <h2>COMUNICACIÓN Y PRENSA</h2>
          <div class="contact-item">
            <p>Rosa de Bustos</p>
            <p>Teléfono: 654 991 862</p>
            <a href="mailto:prensa@une.es">prensa&#64;une.es</a>
          </div>
          <div class="contact-item">
            <p>Julia Sánchez-Arévalo Gallardo</p>
            <p>Teléfono: 913 600 698</p>
            <a href="mailto:prensa2@une.es">prensa2&#64;une.es</a>
          </div>
        </div>
        
        <div class="contact-section">
          <h2>UNEBOOK</h2>
          <div class="contact-item">
            <p>Alejandro Fernández Diego</p>
            <p>Teléfono: 915 996 691</p>
            <a href="mailto:alejandrof@unebook.es">alejandrof&#64;unebook.es</a>
          </div>
        </div>
      </div>
    </div>
    
    <!--Formulario de contacto-->
    <div class="form-container ml-5">
      <form (ngSubmit)="onSubmit()" class="form-contact-container">
        <h3 class="mb-3">Envíanos tu consulta a través de este formulario.</h3>

        <div class="form-group">
          <label for="name">TU NOMBRE</label>
          <input id="name" name="name" type="text" class="form-control" required>
        </div>
        <div class="form-group">
          <label for="email">EMAIL</label>
          <input id="email" name="email" type="email" class="form-control" required>
        </div>
        <div class="form-group">
          <label for="message">MENSAJE</label>
          <textarea id="message" name="message" rows="4" class="form-control" required></textarea>
        </div>
        <button type="submit" class="btn-submit">ENVIAR</button>
      </form>
    </div>
  </div>
