<form class="w-100" [formGroup]="formData" (ngSubmit)="submitForm(formData.value)">
  <div class="mb-3">
    <div class="row">
      <div class="form-group input-group col">
        <input
          class="form-control"
          [attr.aria-label]="'browse.startsWith.input' | translate"
          placeholder="{{'browse.search-form.placeholder' | translate}}"
          type="text"
          name="startsWith"
          formControlName="startsWith"
          [value]="getStartsWith()"
          style="border-radius: 25px; padding: 10px 20px;"
        />
        <span class="input-group-append" style="position: relative; right: 30px;">
          <button class="btn btn-light" type="submit" style="border: none; background: none;">
            <i class="fas fa-search"></i>
          </button>
        </span>
      </div>
    </div>
    <small class="text-muted">{{'browse.startsWith.type_text' | translate}}</small>
  </div>
</form>
