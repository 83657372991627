<div class="search-bar-container">
  <form [formGroup]="searchForm" (ngSubmit)="onSubmit(searchForm.value)" autocomplete="on" class="search-form d-flex align-items-center">
    <input 
      #searchInput
      class="form-control search-input"
      formControlName="query"
      type="text"
      [placeholder]="'Tu búsqueda'"
      [attr.aria-label]="'Buscar'"
    >
    <button 
      class="search-btn" 
      type="submit"
      aria-label="Buscar"
    >
      <em class="fas fa-search"></em>
    </button>
    <select class="form-select" aria-label="Seleccionar campo de búsqueda">
      <option selected>Cualquier campo</option>
      <option value="title">Título</option>
      <option value="author">Autor</option>
      <option value="subject">Tema</option>
    </select>
  </form>
</div>

<!--
<div [title]="'nav.search' | translate" (dsClickOutside)="collapse()">
  <div class="d-inline-block position-relative">
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit(searchForm.value)" autocomplete="on" class="d-flex">
      <input #searchInput [@toggleAnimation]="isExpanded" [attr.aria-label]="('nav.search' | translate)" name="query"
             formControlName="query" type="text" placeholder="{{searchExpanded ? ('nav.search' | translate) : ''}}"
             class="bg-transparent position-absolute form-control dropdown-menu-right pl-1 pr-4"
             [class.display]="searchExpanded ? 'inline-block' : 'none'"
             [tabIndex]="searchExpanded ? 0 : -1"
             [attr.data-test]="'header-search-box' | dsBrowserOnly">
      <button class="submit-icon btn btn-link btn-link-inline" [attr.aria-label]="'nav.search.button' | translate" type="button" (click)="searchExpanded ? onSubmit(searchForm.value) : expand()" [attr.data-test]="'header-search-icon' | dsBrowserOnly">
        <em class="fas fa-search fa-lg fa-fw"></em>
      </button>
    </form>
  </div>
</div>
-->