<section class='about-container row align-items-md-start'>
    <div class='about-info-container col-md-5 col-sm-12 '>
        <h2>Políticas y servicios</h2>
        <p>
            Presentación de los principios rectores de la Plataforma UNE OA.
            Recopilación documental de las Políticas en base a las que funciona.
        </p>
        <p><strong> NORMATIVA SOBRE LA QUE SE RIGE LA PLATAFORMA:</strong></p>
        <p class="custom-p">
            El marco legal que respalda la publicación en acceso abierto de la producción científica institucional se
            encuentra en las siguientes normas:<br><br>
            <strong>Normativa nacional</strong><br><br>
            <strong>Estrategia Nacional de Ciencia Abierta (ENCA) 2023-2027</strong><br>
            La ciencia abierta (open science) implica una evolución de la forma de producir, financiar, comunicar y
            evaluar el conocimiento científico. Supone un importante cambio de paradigma en las actividades de
            investigación, de divulgación de resultados y de medición del rendimiento investigador que afecta tanto a
            las ciencias de la vida, física, ingeniería, matemáticas como a las ciencias sociales y a las humanidades.
            La ciencia abierta contribuye a aumentar la transparencia y fomentan la participación, la cooperación, la
            rendición de cuentas, la capacidad de reutilización del trabajo investigador, el impacto y la
            reproducibilidad de resultados. Así mismo, favorece la democratización y sostenibilidad de los sistemas de
            I+D+I y promueve la diversificación de perfiles en los grupos de investigación y la incorporación de actores
            no académicos en todo el ciclo del proceso investigador, desde el diseño del proyecto hasta su
            evaluación.<br><br>
            La Estrategia Nacional de Ciencia Abierta (ENCA) para el periodo de 2023-2027 recoge todos los compromisos
            relativos a la ciencia abierta adoptados por distintos agentes públicos del sistema, los incluidos en la
            reforma de la Ley de la Ciencia, la Tecnología y la Innovación, aprobada en septiembre de 2022, Ley 17/2022;
            la Estrategia Española de Ciencia, Tecnología e Innovación 2021-2023; y el Plan Estatal de Investigación
            Científica, Tecnológica e Innovación 2021-2022; además de hacer un repaso del contexto internacional y
            nacional en materia de ciencia abierta.<br><br>
            <strong>Ley 2/2023, de 22 de marzo, del Sistema Universitario (BOE nº 70, 23/03/2023), modificada por Ley
                17/2022, de 5 de septiembre. (Texto consolidado)</strong><br><br>
            <strong>Artículo 12. Fomento de la Ciencia Abierta y Ciencia Ciudadana.</strong><br>
            <strong>1.</strong> El conocimiento científico tendrá la consideración de un bien común. Las
            Administraciones Públicas y las universidades promoverán y contribuirán activamente a la Ciencia Abierta
            mediante el acceso abierto a publicaciones científicas, datos, códigos y metodologías que garanticen la
            comunicación de la investigación, a fin de alcanzar los objetivos de investigación e innovación responsables
            que se impulsen desde la comunidad científica, así como los objetivos de libre circulación de los
            conocimientos científicos y las tecnologías que promulga la política europea de investigación y desarrollo
            tecnológico.<br>
            <strong>2.</strong> El personal docente e investigador deberá depositar una copia de la versión final
            aceptada para publicación y los datos asociados a la misma en repositorios institucionales o temáticos de
            acceso abierto, de forma simultánea a la fecha de publicación.
            <strong>3.</strong> La versión digital de las publicaciones académicas se depositará en los repositorios
            institucionales, sin perjuicio de otros repositorios de carácter temático o generalista.<br><br>
            <strong>4.</strong> Los Ministerios de Universidades y de Ciencia e Innovación y los órganos
            correspondientes de las Comunidades Autónomas, cada uno en su ámbito de actuación, promoverán otras
            iniciativas orientadas a facilitar el libre acceso a los datos generados por la investigación (datos
            abiertos) y a desarrollar infraestructuras y plataformas abiertas.<br><br>
            <strong>5.</strong> Los datos, entendidos como aquellas fuentes primarias necesarias para validar los
            resultados de las investigaciones, deberán seguir los principios FAIR (datos fáciles de encontrar,
            accesibles, interoperables y reutilizables) y, siempre que sea posible, difundirse en acceso
            abierto.<br><br>
            <strong>6.</strong> Las universidades deberán promover la transparencia en los acuerdos de suscripción con
            editoriales científicas.<br><br>
            <strong>7.</strong> Las bibliotecas y otras unidades universitarias facilitarán el acceso de la ciudadanía a
            los recursos informativos, digitales y no digitales, así como la formación necesaria para promover la
            difusión de la Ciencia Abierta en la comunidad universitaria y en el conjunto de la sociedad.<br><br>
            <strong>8.</strong> Las agencias de calidad estatal y autonómicas incluirán entre sus criterios y requisitos
            de evaluación la accesibilidad en abierto de los resultados científicos del personal docente e
            investigador.<br><br>
            <strong>9.</strong> Las agencias de calidad utilizarán los repositorios institucionales como forma de acceso
            a la documentación, para garantizar la agilidad de los procedimientos de evaluación.<br><br>
            <strong>10.</strong> Se fomentará la Ciencia Ciudadana como un campo de generación de conocimiento
            compartido entre la ciudadanía y el sistema universitario de investigación. Con el objetivo de promover la
            reflexión científica, tecnológica, humanística, artística y cultural y su aplicación a los retos sociales,
            las universidades favorecerán e impulsarán la colaboración con los actores sociales, y con las
            Administraciones Públicas, en especial con las Comunidades Autónomas y la Administración Local.<br><br>
            <strong>11.</strong> Lo anterior será compatible con la posibilidad de tomar las medidas oportunas para
            proteger, con carácter previo a la publicación científica, los derechos sobre los resultados de la actividad
            de investigación, desarrollo e innovación, de acuerdo con las normativas nacionales y europeas en materia de
            propiedad intelectual e industrial, obtenciones vegetales o secreto empresarial.<br><br>
            <strong>Ley 14/2011, de 1 de junio, de la Ciencia, la Tecnología y la Innovación</strong> (BOE 02/06/2011),
            modificada por Ley 17/2022, de 5 de septiembre. (Texto consolidado)<br><br>
            <strong>Artículo 37. Ciencia abierta.</strong><br>
            Modificado por el Artículo único, Treinta y nueve de la Ley 17/2022, de 5 de septiembre: BOE n.º 214, de
            06/09/2022, Ref. BOE-A-2022-14581. Redacción actual y entrada en vigor a partir del 07/09/2022:<br><br>
            <strong>1.</strong> Los agentes públicos del Sistema Español de Ciencia, Tecnología e Innovación impulsarán
            que se haga difusión de los resultados de la actividad científica, tecnológica y de innovación, y que los
            resultados de la investigación, incluidas las publicaciones científicas, datos, códigos y metodologías,
            estén disponibles en acceso abierto. El acceso gratuito y libre a los resultados...
            se fomentará mediante el desarrollo de repositorios institucionales o temáticos de acceso abierto, propios o
            compartidos
            <strong>2.</strong> El personal de investigación del sector público o cuya actividad investigadora esté
            financiada mayoritariamente con fondos públicos y que opte por diseminar sus resultados de investigación en
            publicaciones científicas, deberá depositar una copia de la versión final aceptada para publicación y los
            datos asociados a las mismas en repositorios institucionales o temáticos de acceso abierto, de forma
            simultánea a la fecha de publicación.<br><br>

            <strong>3.</strong> Los beneficiarios de proyectos de investigación, desarrollo o innovación financiados
            mayoritariamente con fondos públicos deberán cumplir en todo momento con las obligaciones de acceso abierto
            dispuestas en las bases o los acuerdos de subvención de las convocatorias correspondientes. Los
            beneficiarios de ayudas y subvenciones públicas se asegurarán de que conservan los derechos de propiedad
            intelectual necesarios para dar cumplimiento a los requisitos de acceso abierto.<br><br>

            <strong>4.</strong> Los resultados de la investigación disponibles en acceso abierto podrán ser empleados
            por las Administraciones Públicas en sus procesos de evaluación, incluyendo la evaluación del mérito
            investigador.<br><br>

            <strong>5.</strong> El Ministerio de Ciencia e Innovación facilitará el acceso a los repositorios de acceso
            abierto y su interconexión con iniciativas similares nacionales e internacionales, promoviendo el desarrollo
            de sistemas que lo faciliten, e impulsará la ciencia abierta en la Estrategia Española de Ciencia,
            Tecnología e Innovación, reconociendo el valor de la ciencia como bien común y siguiendo las recomendaciones
            europeas en materia de ciencia abierta.<br><br>

            Además del acceso abierto, y siempre con el objetivo de hacer la ciencia más abierta, accesible, eficiente,
            transparente y beneficiosa para la sociedad, los Ministerios de Ciencia e Innovación y de Universidades,
            cada uno en su ámbito de actuación, así como las Comunidades Autónomas en el marco de sus competencias,
            promoverán también otras iniciativas orientadas a facilitar el libre acceso y gestión de los datos generados
            por la investigación (datos abiertos), de acuerdo a los principios internacionales FAIR (sencillos de
            encontrar, accesibles, interoperables y reutilizables), a desarrollar infraestructuras y plataformas
            abiertas, a fomentar la publicación de los resultados científicos en acceso abierto, y la participación
            abierta de la sociedad civil en los procesos científicos, tal como se desarrolla en el artículo 38.<br><br>

            <strong>6.</strong> Lo anterior será compatible con la posibilidad de tomar las medidas oportunas para
            proteger, con carácter previo a la publicación científica, los derechos sobre los resultados de la actividad
            de investigación, desarrollo e innovación, de acuerdo con las normativas nacionales y europeas en materia de
            propiedad intelectual e industrial, obtenciones vegetales o secreto empresarial.<br><br>

            <strong>Real Decreto 99/2011, regulador de las Enseñanzas Oficiales de Doctorado (BOE
                10/02/2011)</strong><br>
            <strong>Artículo 14. Evaluación y defensa de la tesis doctoral.</strong><br><br>

            <strong>5.</strong> Una vez aprobada la tesis doctoral, la universidad se ocupará de su archivo en formato
            electrónico abierto en un repositorio institucional y remitirá, en formato electrónico, un ejemplar de la
            misma, así como toda la información complementaria que fuera necesaria al Ministerio de Educación a los
            efectos oportunos.<br><br>

            <strong>6.</strong> En circunstancias excepcionales determinadas por la comisión académica del programa,
            como pueden ser, entre otras, la participación de empresas en el programa o Escuela, la existencia de
            convenios de confidencialidad con empresas o la posibilidad de generación de patentes que recaigan sobre el
            contenido de la tesis, las universidades habilitarán procedimientos para desarrollar los apartados 4 y 5
            anteriores que aseguren la no publicidad de estos aspectos.<br><br>

            <strong>Normativa propia de la Plataforma UNE OA con respecto al depósito de publicaciones por parte de los
                Servicios de Publicación de la UNE.</strong>
        </p>
        <p><strong>POLÍTICAS:</strong></p>
        <div style="margin-bottom: 1rem;" class="custom-p">
            Las políticas que rigen el funcionamiento y la gestión de la Plataforma UNE OA se definen a continuación:
            <ul>
                <li>Política de contenidos y colecciones <em>(enlace al documento)</em></li>
                <li>Política de metadatos <em>(enlace al documento)</em></li>
                <li>Política de datos <em>(enlace al documento)</em></li>
                <li>Política de depósito</li>
                <li>Política de retención, edición, sustitución y eliminación de registros <em>(enlace al
                        documento)</em>
                </li>
                <li>Política de preservación y formatos <em>(enlace al documento)</em></li>
                <li>Política de privacidad <em>(enlace al documento)</em></li>
            </ul>

        </div>
        <p><strong>DEPOSITAR DOCUMENTOS:</strong></p>
        <p class="custom-p">
            El procedimiento para depositar documentos por parte de los Servicios de Publicación asociados a la UNE en
            la Plataforma UNE OA es el siguiente:
            Especificación del procedimiento a llevar a cabo por parte de los socios cuando ya esté en marcha el
            repositorio.
            Dependerá de las herramientas con las que trabajen sus flujos de gestión editorial (OMP/OJS).
            Habrá que ver cómo se envían los archivos de los documentos (que siempre serán la versión del editor)
            En la <strong> política de depósito</strong> se establecen las condiciones para el archivo de documentos.

        </p>
        <p><strong>INFORMACIÓN SOBRE EL REPOSITORIO COMO PROVEEDOR DE DATOS OAI-PMH</strong></p>
        <div style="margin-bottom: 1rem;" class="custom-p">
            <ul>
                <ul style="list-style-type: none; padding-left: 0;">
                    <li>Nombre del Repositorio OAI-PMH: Plataforma UNE OA</li>
                    <li>E-Mail de contacto: digital&#64;une.es</li> 
                    <li>URL repositorio: <a target="_blank" href="https://plataforma.une.oa.es">plataforma.une.oa.es</a></li> <!-- Se usa un enlace en el caso de la URL -->
                    <li>Identificador del repositorio como Proveedor de Datos (espacio de nombres del identificador OAI): <a href="https://plataforma.une.oa.es/oai2d?verb=Identify">https://plataforma.une.oa.es/oai2d?verb=Identify</a></li>
                    <li>Versión del Protocolo.</li>
                    <li>Última fecha de registro.</li>
                    <li>Granularidad de fecha: YYYY-MM-DD hh:mm:ss</li>
                    <li>Modo de eliminación: transitoria</li>
                </ul>
            </ul>
        </div>
    </div>
    <div class="col-md-2 col-sm-12 mt-3 fixed-sidebar">
        <div class="about-card">
          <li><a routerLink="/quienes-somos">Quienes Somos</a></li>
          <li><a routerLink="/buscador">El buscador</a></li>
          <li><a routerLink="/tecnologia">Tecnología</a></li>
          <li><a routerLink="/politica-servicios">Política y servicios</a></li>
        </div>
      </div>
</section>