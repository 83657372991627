<!--Cards de estadísticas de UNE-->
<section class='statistics-card-container'>
  <div>
    <h2>150</h2>
    <p>Libros</p>
  </div>

  <div>
    <h2>200</h2>
    <p>Capítulos libros</p>
  </div>

  <div>
    <h2>120</h2>
    <p>Libros diamante</p>
  </div>

  <div>
    <h2>140</h2>
    <p>Libros con sello de calidad</p>
  </div>

  <div>
    <h2>220</h2>
    <p>Editoriales</p>
  </div>
</section>