<section class='about-container row align-items-md-start'>
  <div class='about-info-container col-md-5 col-sm-12 '>
    <h2>Quiénes somos</h2>
    <p>
      La Plataforma UNE OA es el repositorio que recopila la producción científica de monografías de
      los diferentes Servicios de Publicación que forman parte de la UNE (Unión de Editoriales
      Universitarias Españolas).

    </p>

    <p>
      La Plataforma UNE OA nació en 2024 con el objetivo garantizar la preservación, el acceso y la
      difusión en abierto dichas publicaciones.s
    </p>

    <p>
      Entre sus objetivos se encuentran:
    </p>

 
    <ul>

      <li>
        El impulso de una infraestructura a modo de punto de acceso común a la producción científica
        de los diferentes Servicios de Publicación que forman parte de la UNE garantizando su
        interoperabilidad según los estándares de la comunidad mundial.
      </li>

      <li>
        Promover y facilitar la adopción del acceso abierto los diferentes Servicios de Publicación que
        forman parte de la UNE, ayudando a su compromiso de la alineación con respecto a las políticas
        de Acceso Abierto y Ciencia Abierta.
      </li>

      <li>
        Dotar de una mayor visibilidad y difusión, tanto nacional como internacional, a las
        publicaciones científicas de los Servicios de Publicación que conforman la UNE.
      </li>
    </ul>
  

    <p>
      Si deseas contactar con el equipo de la Plataforma UNE OA, puedes hacerlo a través de la
      dirección de correo electrónico digital&#64;une.es
    </p>



  </div>
  <!-- <div class="col-md-2 col-sm-12 mt-3"> -->
    <!-- <ol class="list-group list-group-numbered about-info-card">
      <li class="list-group-item">Quiénes somos</li>
      <li class="list-group-item">Tecnología</li>
      <li class="list-group-item">Política y servicios</li>
      <li class="list-group-item">Editoriales</li>
    </ol> -->

    <div class="col-md-2 col-sm-12 mt-3 fixed-sidebar">
      <div class="about-card">
        <li><a routerLink="/quienes-somos">Quienes Somos</a></li>
        <li><a routerLink="/buscador">El buscador</a></li>
        <li><a routerLink="/tecnologia">Tecnología</a></li>
        <li><a routerLink="/politica-servicios">Política y servicios</a></li>
      </div>
    </div>
  <!-- </div> -->
</section>