<div *ngIf="moreThanOneLanguage" class="language-select-container">
  <label for="languageSelect" [attr.aria-label]="'nav.language' | translate" class="sr-only">
    {{ 'nav.language' | translate }}
  </label>
  
  <select id="languageSelect"
          (change)="useLang($event.target.value)"
          [attr.aria-label]="'nav.language' | translate"
          class="form-select"
          data-test="lang-switch"
          tabindex="0">
    <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">
      {{ langLabel(lang) }}
    </option>
  </select>
</div>
