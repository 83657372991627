<div class="navbar-nav h-100 align-items-md-stretch gapx-3" role="menubar" id="main-site-navigation" [ngClass]="(isMobile$ | async) ? 'navbar-nav-mobile' : 'navbar-nav-desktop'">

  <ng-container *ngFor="let section of sectionMap">
    
    <a *ngIf="section.label !== 'QUIENES SOMOS'" class="nav-link" [routerLink]="section.route">
      {{ section.label }}
    </a>
  
    <div *ngIf="section.label === 'QUIENES SOMOS'" class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" id="quienesSomosDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        {{ section.label }}
        <i class="fas fa-chevron-down"></i>
      </a>
      <ul class="dropdown-menu" aria-labelledby="quienesSomosDropdown">
        <li><a class="dropdown-item" [routerLink]="'/quienes-somos'">¿Quiénes somos?</a></li>
        <li><a class="dropdown-item" [routerLink]="'/buscador'">El buscador</a></li>
        <li><a class="dropdown-item" [routerLink]="'/tecnologia'">Tecnología</a></li>
        <li><a class="dropdown-item" [routerLink]="'/politica-servicios'">Política y servicios</a></li>
      </ul>
    </div>

  </ng-container>

</div>
