<section class='about-container row align-items-md-start'>
  <div class='about-info-container col-md-5 col-sm-12 '>
    <h2>Tecnología</h2>
    <p>
      Descripción de la Tecnología utilizada para la construcción de la Plataforma UNE OA, así como las pautas seguidas
      para la creación perfiles de metadatos para que el repositorio sea compatible e interoperable con otras
      plataformas, como por ejemplo, OpenAIRE [A tener en cuenta HispanaPRO].


    </p>
    <p><strong>Construcción del repositorio / Plataforma UNE OA en base al software DSpace 7</strong></p>
    <p> El uso de OAI-PMH como protocolo de transferencia y recopilación de metadatos bibliográficos.</p>
    Directrices y su perfil de aplicación.
    <a 
    class="a-links"
    href="https://www.openarchives.org/OAI/openarchivesprotocol.html" target="_blank">
      https://www.openarchives.org/OAI/openarchivesprotocol.html
    </a>
    <p><strong>Presentación del Perfil de aplicación de metadatos utilizado </strong></p>
    <p><em>(A modo de ejemplo, el resultado final del Perfil de Metadatos de la Plataforma UNE OA:</em>
      <a target="_blank"
      class="a-links"
        href="https://openaire-guidelines-for-literature-repository-managers.readthedocs.io/en/v4.0.0/application_profile.html#application-profile">
        https://openaire-guidelines-for-literature-repository-managers.readthedocs.io/en/v4.0.0/application_profile.html#application-profile
      </a>)
    </p>
    <p>
      Obligatorio (M) - Obligatorio si corresponde (MA) - Recomendado (R) - Opcional (O)
    </p>
    <p>La Plataforma UNE OA utiliza las siguientes abreviaturas de espacios de nombres:</p>
    <ul>
      <li> dc: http://purl.org/dc/elements/1.1/</li>
      <li>dcterms: http://purl.org/dc/terms/</li>
      <li>datacite: http://datacite.org/schema/kernel-4</li>
      <li>oaire: http://namespace.openaire.eu/schema/oaire/</li>

    </ul>

    <p> Descripción general del perfil de aplicación:</p>
    <p>
      Por cada Campo de Metadato (Dublin Core) se incluirá la descripción del elemento de metadato y el refinamiento por
      vocabulario
      (cuando lo tenga que tener. Ejemplo:
      <a target="_blank"
      class="a-links"
        href="https://vocabularies.coar-repositories.org/resource_types/">https://vocabularies.coar-repositories.org/resource_types/</a>
      ).
    </p>

    <ol>
      <li>Título (M)</li>
      <li>Creador (M)</li>
      <li>Colaborador (MA)</li>
      <li>Referencia de Financiamiento (MA)</li>
      <li>Identificador alternativo (R)</li>
      <li>Identificador relacionado (R)</li>
      <li>Fecha del Período de Embargo (MA)</li>
      <li>Idioma (MA)</li>
      <li>Editor (MA)</li>
      <li>Fecha de publicación (M)</li>
      <li>Tipo de recurso (M)</li>
      <li>Descripción (MA)</li>
      <li>Formato (R)</li>
      <li>Identificador de recursos (M)</li>
      <li>Derechos de acceso (M)</li>
      <li>Fuente (R)</li>
      <li>Asunto (MA)</li>
      <li>Condición de licencia (R)</li>
      <li>Cobertura (R)</li>
      <li>Tamaño (O)</li>
      <li>Ubicación geográfica (O)</li>
      <li>Versión de recursos (R)</li>
      <li>Ubicación del archivo (MA)</li>
      <li>Título de la cita (R)</li>
      <li>Volumen de citas (R)</li>
      <li>Problema de citación (R)</li>
      <li>Página de inicio de citas (R)</li>
      <li>Página final de la cita (R)</li>
      <li>Edición de citas (R)</li>
      <li>Lugar de conferencias de citas (R)</li>
      <li>Fecha de la conferencia de citación (R)</li>
      <li>Audiencia (O)</li>
    </ol>
    

  </div>
  <div class="col-md-2 col-sm-12 mt-3 fixed-sidebar">
    <div class="about-card">
      <li><a routerLink="/quienes-somos">Quienes Somos</a></li>
      <li><a routerLink="/buscador">El buscador</a></li>
      <li><a routerLink="/tecnologia">Tecnología</a></li>
      <li><a routerLink="/politica-servicios">Política y servicios</a></li>
    </div>
  </div>
</section>