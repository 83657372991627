<section>
  <ds-results-back-button *ngIf="showBackButton$ | async" [back]="back"></ds-results-back-button>
  <div class="row" *ngIf="iiifEnabled">
    <div class="col-12">
      <ds-mirador-viewer id="iiif-viewer" [object]="object" [searchable]="iiifSearchEnabled"
        [query]="iiifQuery$ | async">
      </ds-mirador-viewer>
    </div>
  </div>

  <div class="mb-3 item-title">
    <div class="d-flex flex-row">
      <ds-item-page-title-field [item]="object" class="mr-auto">
      </ds-item-page-title-field>
      <ds-dso-edit-menu></ds-dso-edit-menu>
    </div>

    <!--Autor-->
    <div class="autor-info-container">
      <h5 class="author-container">
        <ds-metadata-representation-list class="ds-item-page-mixed-author-field authors-list" [parentItem]="object"
          [itemType]="'Person'" [metadataFields]="['dc.contributor.author', 'dc.creator']">
        </ds-metadata-representation-list>
      </h5>
    </div>

    <!--Botones: Cómo citar la obra/recurso ; Exportar ; Ver estadísticas-->
    <div class="action-btns">
      <button>Cómo citar la obra/recurso</button>
      <button>Exportar</button>
      <button>Ver PDF</button>
      <!-- <ds-pdf-viewer pdfUrl="hola"></ds-pdf-viewer> -->
      <!-- <button [routerLink]="['/bitstreams', object.id, 'download']">Ver PDF</button> -->
      <!--<div>
        <ds-file-download-link [bitstream]="file" [item]="item">
            {{"item.page.filesection.download" | translate}}
        </ds-file-download-link>
    </div>!-->
    </div>

  </div>

  <div class="row fw-bold">
    <!--Descripción-->
    <div class="col-xs-12 col-md-6">
      <ds-item-page-abstract-field [item]="object"></ds-item-page-abstract-field>
      <ds-generic-item-page-field [item]="object" [fields]="['dc.description']">
      </ds-generic-item-page-field>

      <!--Información sobre los ficheros-->
      <div class="container mt-4">
        <h5 class="fw-bolder">Ficheros en este item</h5>
        <table class="table table-borderless">
          <tbody>
            <tr>
              <th scope="row">Nombre</th>
              <td><ds-generic-item-page-field [item]="object" [fields]="['dc.title']"></ds-generic-item-page-field></td>
            </tr>
            <tr>
              <th scope="row">Tamaño</th>
              <td>780.5Kb</td>
            </tr>
            <tr>
              <th scope="row">Formato</th>
              <td><ds-generic-item-page-field [item]="object" [fields]="['dc.format']"></ds-generic-item-page-field>
              </td>
            </tr>
          </tbody>
        </table>
      </div>


      <!--Ocultando vista de: Palabras clave, URI y colecciones
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.subject']"
      [separator]="', '"
      [label]="'item.page.subject'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.identifier.citation']"
      [label]="'item.page.citation'">
    </ds-generic-item-page-field>
    <ds-item-page-uri-field [item]="object"
      [fields]="['dc.identifier.uri']"
      [label]="'item.page.uri'">
    </ds-item-page-uri-field>
    <ds-item-page-collections [item]="object"></ds-item-page-collections>
    <ds-item-page-uri-field [item]="object"
      [fields]="['coar.notify.endorsedBy']"
      [label]="'item.page.endorsement'">
    </ds-item-page-uri-field>
    <ds-item-page-uri-field [item]="object"
      [fields]="['datacite.relation.isReviewedBy']"
      [label]="'item.page.review'">
    </ds-item-page-uri-field>
    <ds-item-page-uri-field [item]="object"
      [fields]="['datacite.relation.isSupplementedBy']"
      [label]="'item.page.supplemented'">
    </ds-item-page-uri-field>
    <ds-item-page-uri-field [item]="object"
      [fields]="['datacite.relation.isReferencedBy']"
      [label]="'item.page.referenced'">
    </ds-item-page-uri-field>
    <ds-item-page-cc-license-field [item]="object" [variant]="'full'">
    </ds-item-page-cc-license-field>
    <div>
      <a class="btn btn-outline-primary item-link-btn" [routerLink]="[itemPageRoute + '/full']" role="button">
        {{"item.page.link.full" | translate}} →
      </a>
    </div>
    
    -->
      <div>
        <a class="btn btn-outline-primary item-link-btn" [routerLink]="[itemPageRoute + '/full']" role="button">
          {{"item.page.link.full" | translate}} →
        </a>
      </div>
    </div>

    <!--Imagen y sus elementos-->
    <div class="col-xs-12 col-md-6 item-img-info">
      <ng-container *ngIf="!(mediaViewer.image || mediaViewer.video)">
        <!--Imagen-->
        <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
          <ds-thumbnail [thumbnail]="object?.thumbnail | async"></ds-thumbnail>
        </ds-metadata-field-wrapper>

        <div class="mb-2 article-info">
          <div>
            <strong>Descargas</strong>
            <p>2848</p>
          </div>

          <button>Ver estadísticas</button>
        </div>

      </ng-container>

      <div *ngIf="mediaViewer.image || mediaViewer.video" class="mb-2">
        <ds-media-viewer [item]="object"></ds-media-viewer>
      </div>

      <!--Autores y fecha-->
      <!--<div class="item-img-info-container">
        <ds-item-page-file-section [item]="object"></ds-item-page-file-section>
        <ds-item-page-date-field [item]="object"></ds-item-page-date-field>
        <ds-metadata-representation-list class="ds-item-page-mixed-author-field" [parentItem]="object"
          [itemType]="'Person'" [metadataFields]="['dc.contributor.author', 'dc.creator']"
          [label]="'relationships.isAuthorOf' | translate">
        </ds-metadata-representation-list>
        
        <ds-generic-item-page-field [item]="object" [fields]="['journal.title']" [label]="'item.page.journal-title'">
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [item]="object" [fields]="['journal.identifier.issn']"
          [label]="'item.page.journal-issn'">
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [item]="object" [fields]="['journalvolume.identifier.name']"
          [label]="'item.page.volume-title'">
        </ds-generic-item-page-field>
        <ds-generic-item-page-field [item]="object" [fields]="['dc.publisher']" [label]="'item.page.publisher'">
        </ds-generic-item-page-field>
      </div>-->
    </div>
  </div>
</section>

<!--Tablas informativas-->
<div class="tables-container">
  <table class="table table-hover">
    <tbody>
      <tr>
        <th scope="row">Materias</th>
        <td>
          <ds-generic-item-page-field [item]="object" [fields]="['dc.subject']"></ds-generic-item-page-field>
        </td>
      </tr>
      <tr>
        <th scope="row">Palabras clave</th>
        <td>
          <ds-generic-item-page-field [item]="object" [fields]="['dc.subject.keywords']"></ds-generic-item-page-field>
        </td>
      </tr>
      <tr>
        <th scope="row">Editorial</th>
        <td><ds-generic-item-page-field [item]="object" [fields]="['dc.publisher']"></ds-generic-item-page-field></td>
      </tr>
      <tr>
        <th scope="row">Año</th>
        <td><ds-generic-item-page-field [item]="object" [fields]="['dc.date']"></ds-generic-item-page-field></td>
      </tr>
      <tr>
        <th scope="row">Número de edición</th>
        <td><ds-generic-item-page-field [item]="object" [fields]="['dc.description.version']"></ds-generic-item-page-field></td>
      </tr>
      <tr>
        <th scope="row">ISBN</th>
        <td>
          <ds-generic-item-page-field [item]="object" [fields]="['dc.identifier.isbn']"></ds-generic-item-page-field>
        </td>
      </tr>
      <tr>
        <th scope="row">DOI</th>
        <td>
          <a [href]="object.metadata['dc.identifier.doi']" target="_blank">
            <ds-item-page-uri-field 
              [item]="object" 
              [fields]="['dc.identifier.doi']">
            </ds-item-page-uri-field>
          </a>
        </td>
      </tr>
      <tr>
        <th scope="row">Licencia</th>
        <td><ds-generic-item-page-field [item]="object" [fields]="['dc.rights.license']"></ds-generic-item-page-field></td>
      </tr>
    </tbody>
  </table>


  <table class="table table-hover">
    <tbody>
      <tr>
        <th scope="row">Idioma de publicación</th>
        <td><ds-generic-item-page-field [item]="object" [fields]="['dc.language.iso']"></ds-generic-item-page-field></td>
      </tr>
      <tr>
        <th scope="row">Tipo de recurso</th>
        <td><ds-generic-item-page-field [item]="object" [fields]="['dc.type']"></ds-generic-item-page-field></td>
      </tr>
      <tr>
        <th scope="row">Colección</th>
        <td><ds-generic-item-page-field [item]="object" [fields]="['dc.relation.ispartof']"></ds-generic-item-page-field></td>
      </tr>
      <tr>
        <th scope="row">Materias controladas</th>
        <td></td>
      </tr>
      <tr>
        <th scope="row">Derechos de uso</th>
        <td><ds-generic-item-page-field [item]="object" [fields]="['dc.rights']"></ds-generic-item-page-field></td>
      </tr>
      <tr>
        <th scope="row">Handle (URI)</th>
        <td><a [href]="object?.metadata['dc.identifier.uri'][0].value" target="_blank">
            <ds-generic-item-page-field [item]="object" [fields]="['dc.identifier.uri']"></ds-generic-item-page-field>
          </a></td>
      </tr>
      <tr>
        <th scope="row">En UNEBOOK</th>
        <td>
          <a [href]="object.metadata['dcterms.mediator']" target="_blank">
            <ds-item-page-uri-field 
              [item]="object" 
              [fields]="['dcterms.mediator']">
            </ds-item-page-uri-field>
          </a>
        </td>
      </tr>
      <tr>
        <th scope="row">Fecha de publicación en abierto</th>
        <td>
          <ds-generic-item-page-field [item]="object" [fields]="['dc.date.available']"></ds-generic-item-page-field>
        </td>
      </tr>
      <tr>
        <th scope="row">Financiación</th>
        <td>
        <ds-generic-item-page-field [item]="object" [fields]="['dc.provenance']"></ds-generic-item-page-field>
      </td>
      </tr>
    </tbody>
  </table>
</div>